.btn-social {
  position: fixed;
  display: block;
  width: 40px;
  height: 40px;
  margin: 0;
  bottom: 20px;
  right: 15px;
  z-index: 5;
}

.btn-social__icon {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media screen and (max-width: 576px) {
  .btn-social {
    bottom: 85px;
  }
}