@font-face {
  font-family: "Gilroy";
  src: url("../../assets/fonts/Gilroy-ExtraBold.otf");
  src: local("Gilroy"), local("Gilroy"),
    url("../../assets/fonts/Gilroy-ExtraBold.otf") format("opentype");
  font-weight: bold;
}
/*light version*/
@font-face {
  font-family: "Gilroy";
  src: url("../../assets/fonts/Gilroy-Light.otf");
  src: local("Gilroy"), local("Gilroy"),
    url("../../assets/fonts/Gilroy-Light.otf") format("opentype");
}

.invierte {
  display: grid;
  grid-template-columns: 40% 60%;
  width: 70%;
  margin: -180px auto 20px auto;
}
.imagenCompu {
  background-image: url(../../assets/images/rueda.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: relative;
  text-align: center;
  overflow: visible;
  padding: 200px 0;
}
.imagenCompu img {
  width: 70%;
}
.liderMundialTitulo {
  font-size: 56px;
  font-weight: bold;
  padding-top: 200px;
  line-height: 1.2;
}
.liderMundial p {
  font-size: 24px;
}
.botonContacto {
  margin: 5px 0;
}
.contenedorCombinacion {
  text-align: center;
  width: 70%;
  margin: -20px auto 0 auto;
  position: relative;
  z-index: 3;
}
.contenedorCombinacionTitulo {
  font-size: 48px;
  color: #111111;
  font-weight: bold;
}
.contenedorCombinacion p {
  font-size: 20px;
  margin: 5px 20px;
}
.contenedorHabilidades {
  margin: 50px 0 90px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 32px;
}
.habilidadSubtitulo {
  margin: 16px 0 8px 0;
  font-size: 24px;
  color: #324871;
  font-weight: bold;
}
.habilidadUno p,
.habilidadDos p,
.habilidadTres p {
  font-size: 16px;
  font-family: "Gilroy", sans-serif;
}
.contenedorTipoCuentas {
  background-color: black;
  padding-top: 30px;
  padding-bottom: 90px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 85%);
}
.tipoCuentas {
  width: 70%;
  margin: 10px auto;
  display: grid;
  grid-template-columns: 1fr 2fr;
}
.cuentasBotonTitulo {
  color: white;
  font-weight: bold;
  font-size: 56px;
  margin-top: 20%;
  margin-right: 70px;
}
.explicacionCuentas {
  color: white;
  padding: 30px 0;
}
.explicacionCuentas div p {
  font-size: 56px;
  font-weight: bold;
  border-top: 2px solid #13ffcf;
}

.cuentaInicial {
  width: 28%;
  display: inline-block;
}
.cuentaBasic {
  margin-left: 5%;
  width: 35%;
  display: inline-block;
  margin-right: 30%;
}
.cuentaPro {
  width: 35%;
  display: inline-block;
}
.cuentaExpert {
  margin-left: 5%;
  width: 40%;
  display: inline-block;
}
.cuentaBlack {
  width: 80%;
}
.contenedorMercado {
  width: 80%;
  margin: 90px auto;
}
.mercadoTexto {
  margin-right: 30%;
  margin-left: 5%;
}
.dudasTexto {
  width: 70%;
  margin-left: 30%;
}
.mercadoTextoTitulo,
.dudasTextoTitulo {
  font-size: 40px;
  font-weight: bold;
  line-height: 1.2;
}
.dudasTextoTitulo {
  margin-top: 20%;
}
.mercadoTexto p,
.dudasTexto p {
  font-size: 20px;
}
.dudasTexto p {
  margin-right: 30px;
}
.mercado {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.mercadoImagen {
  text-align: right;
}
.mercadoImagen img {
  width: 60%;
}
.dudas {
  margin-top: 100px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.dudasImagen {
  text-align: center;
}
.dudasImagen img {
  width: 55%;
  margin-top: 10%;
}
.botonMercado {
  background-color: black;
  color: #13ffcf;
}
.botonDudas {
  background-color: white;
  border: 1px solid #197870;
  border-radius: 5px;
}
@media screen and (min-width: 1900px) {
  .invierte {
    width: 80%;
  }
  .liderMundial {
    margin-left: 20px;
    margin-top: 40px;
  }
}
@media screen and (max-width: 1850px) {
  .liderMundialTitulo {
    font-size: 48px;
  }
  .tipoCuentas {
    width: 90%;
  }
  .explicacionCuentas div p {
    font-size: 55px;
    text-align: left;
  }
  .dudasTexto {
    width: 80%;
    margin-left: 20%;
  }
  .mercadoImagen img {
    width: 80%;
  }
}

@media screen and (max-width: 1300px) {
  .contenedorCombinacion {
    margin: 50px auto 0 auto;
  }
  .explicacionCuentas div p {
    font-size: 50px;
  }
  .contenedorMercado {
    width: 90%;
  }
  .mercadoImagen img {
    width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .cuentaInicial {
    width: 35%;
  }
  .cuentaBasic {
    margin-left: 5%;
    width: 40%;
    margin-right: 20%;
  }
  .cuentaPro {
    width: 40%;
  }
  .cuentaExpert {
    margin-left: 5%;
    width: 50%;
  }
  .cuentaBlack {
    width: 95%;
  }
}
@media screen and (max-width: 992px) {
  .contenedorHabilidades {
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
  .invierte {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: "imagenCompu" "liderMundial";
    width: 90%;
  }
  .imagenCompu {
    grid-area: imagenCompu;
    max-height: 150px;
  }
  .liderMundial {
    grid-area: liderMundial;
    text-align: center;
  }

  .liderMundial {
    padding-bottom: 50px;
  }

  .contenedorCombinacion {
    width: 80%;
  }
  .tipoCuentas {
    grid-template-columns: 1fr;
  }
  .cuentasBoton {
    text-align: center;
  }
  .cuentasBotonTitulo {
    margin: 20px auto;
  }
  .cuentaBlack {
    width: 100%;
  }
  .cuentaPro {
    width: 45%;
  }
  .cuentaExpert {
    margin-left: 5%;
    width: 50%;
  }
  .cuentaInicial {
    margin-left: 10%;
  }
  .cuentaBasic {
    margin-right: 10%;
  }
  .explicacionCuentas div p {
    font-size: 40px;
  }
  .mercado {
    grid-template-columns: 1fr;
  }
  .contenedorMercado {
    width: 90%;
  }
  .mercadoImagen,
  .mercadoTexto {
    text-align: center;
    margin-bottom: 30px;
  }
  .mercadoTexto {
    margin-left: auto;
    margin-right: auto;
  }
  .mercadoImagen img {
    width: 70%;
  }
  .botonMercado,
  .botonDudas {
    width: 50%;
    margin: 20px auto;
  }
  .dudas {
    margin-top: 40px;
    grid-template-columns: 1fr;
    grid-template-areas: "dudasImagen" "dudasTexto";
  }
  .dudasTexto {
    grid-area: dudasTexto;
    margin: 20px auto;
    text-align: center;
  }
  .dudasTextoTitulo {
    margin-top: 30px;
  }
  .dudasImagen {
    grid-area: dudasImagen;
  }
  .botonDudas span {
    margin: 15px 0;
  }
}
.iconoContactanos {
  color: var(--colorPrincipal);
  margin-right: 10px;
  padding-top: 2px;
}

.cuentaBlack div,
.cuentaPro div,
.cuentaExpert div,
.cuentaInicial div,
.cuentaBasic div {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 500;
}

@media screen and (max-width: 576px) {
  .cuentaBlack,
  .cuentaPro,
  .cuentaExpert,
  .cuentaInicial,
  .cuentaBasic {
    display: block;
    width: 90%;
    margin: 10px auto;
  }

  .liderMundialTitulo {
    padding-top: 20px;
  }

  .contenedorCombinacionTitulo {
    font-size: 40px;
  }
  .botonContacto {
    font-size: 15px;
    padding: 10px 30px;
  }
  .explicacionCuentas div p {
    font-size: 30px;
  }
  .botonMedida {
    width: 80%;
  }
  .botonMedida span {
    font-size: 20px;
  }
  .explicacionCuentas div p {
    font-size: 30px;
  }
  .cuentasBotonTitulo {
    font-size: 48px;
  }
  .mercadoTextoTitulo {
    font-size: 30px;
  }
  .botonMercado span,
  .botonDudas span {
    font-size: 20px;
  }
  .botonMercado,
  .botonDudas {
    width: 80%;
  }
}
