.sliderUnitario {
  text-align: center;
  transition: all 0.3s ease-in;
}
.sliderUnitario:focus {
  outline-color: var(--colorPrincipal);
}
.sliderUnitario img {
  width: 100px;
  filter: grayscale(100%);
  margin: auto;
}
.precioElemento {
  color: white;
  font-weight: bold;
  font-size: 40px;
}
.nombreElemento {
  color: #13ffcf;
  font-size: 18px;
  display: inline-block;
}
.cambioElemento {
  display: inline-block;
  font-size: 16px;
  margin-left: 25px;
}
.precioVerde {
  color: #13ff23;
}
.precioRojo {
  color: rgb(255, 19, 19);
}
