.contacto {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding-bottom: 56px;
}

.imagenContacto img {
  width: 80%;
  margin: 0 10%;
}
.contenedorContactanos {
  padding-top: 100px;
  width: 90%;
  margin: 0 auto;
}

.tituloContacto {
  font-size: 48px;
  font-weight: bold;
  color: white;
  margin-top: 40px;
}
.contacto p {
  font-size: 20px;
  color: white;
  font-weight: lighter;
}
.contacto span {
  color: white;
  display: block;
  padding-bottom: 10px;
  margin: 10px 0;
  border-bottom: 1px solid white;
  width: 70%;
}
.contacto span:last-of-type {
  border-bottom: none;
}
.contenedorRevolucion {
  position: relative;
  color: white;
  margin-top: -10px;
  background-color: black;
  clip-path: polygon(0 0, 100% 0, 100% 84%, 0% 100%);
  margin-bottom: 200px;
}
.revolucion {
  display: grid;
  grid-template-columns: 2fr 1fr;
  width: 80%;
  margin: 0 auto;
  padding-bottom: 20px;
}

.imagenRevolucion {
  text-align: center;
}
.imagenRevolucion img {
  width: 100%;
}

.textoRevolucionTitulo {
  font-size: 48px;
  font-weight: bold;
}
.textoRevolucion p {
  font-size: 20px;
  font-weight: lighter;
}
.contenedorTextoRevolucion {
  margin-top: 40%;
}
.areaContacto {
  align-self: center;
  margin-left: 20%;
}
.botonContactanos {
  margin: 20px 0;
}
@media screen and (max-width: 1480px) {
  .contenedorTextoRevolucion {
    margin-top: 20%;
  }
}
@media screen and (max-width: 1300px) {
  .contenedorTextoRevolucion {
    margin-top: 12%;
  }
}
@media screen and (max-width: 1200px) {
  .areaContacto {
    margin-left: 0;
  }
  .contenedorRevolucion {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
  .revolucion {
    padding-bottom: 0px;
    grid-gap: 10px;
  }
}
@media screen and (max-width: 992px) {
  .contacto,
  .revolucion {
    grid-template-columns: 1fr;
    grid-template-areas: "imagenContacto" "areaContacto";
  }
  .areaContacto {
    grid-area: areaContacto;
    text-align: center;
  }
  .imagenContacto {
    grid-area: imagenContacto;
  }
  .contacto span {
    margin: 10px auto;
    width: 70%;
  }
  .imagenRevolucion img {
    width: 80%;
  }
  .contenedorRevolucion {
    padding-bottom: 200px;
  }
  .textoRevolucion {
    text-align: center;
  }
}

@media screen and (max-width: 576px) {
}
