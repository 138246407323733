* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navInicio {
  background-color: transparent;
}
.cabeceraInicio {
  background-size: cover;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.253),
      rgb(0, 0, 0)
    ),
    url("../../assets/images/bg.webp");
  background-position: top;
}
.contenedorTecnologia {
  padding-top: 200px;
  width: 80%;
  margin: 0 auto;
  position: relative;
}
.ruedaInicio {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
}

.tecnologia h1 {
  font-size: 56px;
  font-weight: bold;
  color: white;
  width: 50%;
}
.contenedorGanancias {
  position: relative;
  margin-top: -3px;
}
.contenedorGanancias:after {
  width: 100%;
  height: 100%;
  content: " ";
  top: 0;
  left: 0;
  background: black;
  clip-path: polygon(0 0, 100% 0%, 100% 69%, 0% 100%);
  position: absolute;
  z-index: -1;
}
.ganancias {
  padding-top: 60px;
  padding-bottom: 80px;
  width: 80%;
  margin: 0 auto;
}

.textosGanancias {
  padding-top: 8%;
}

.textosGananciasTitulo {
  font-size: 48px;
  font-weight: bold;
  color: white;
}

.ganancias p {
  font-size: 20px;
  letter-spacing: 1px;
  color: #ffffff;
  opacity: 1;
}

.ganancias button {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 9px 16px #e54f3e38;

  border-radius: 5px;
  opacity: 1;
}

.ganancias img {
  box-shadow: 0px 10px 20px #00000096;
  opacity: 1;
  width: 100%;
}

.contenedorTituloBeneficios {
  padding-top: 250px;
  background-color: #f9f9f9;
  margin: -150px auto 30px;
  position: relative;
  z-index: -2;
  width: 80%;
  text-align: center;
}

.contenedorTituloBeneficiosTitulo {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 50px;
}
.beneficios {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.tituloBeneficio {
  margin: 5px 0;
  font-size: 24px;
  font-weight: bold;
}
.beneficios div p {
  margin: 5px;
  font-size: 24px;
}
.contenedorTestimonios {
  margin-top: 200px;
  margin-bottom: 200px;
}
.testimonios {
  margin: 20px auto;
  text-align: center;
}
.testimonioTitulo {
  font-size: 48px;
  font-weight: bold;
}
.testimoniosSubtitulo {
  font-size: 20px;
  width: 50%;
  margin: auto;
}
.gridTestimonios {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  margin: 50px auto 20px auto;
  width: 70%;
}
.imagenTestimonio {
  width: 150px;
}
.testimonioUno,
.testimonioDos,
.testimonioTres {
  display: grid;
  grid-template-columns: auto 1fr;
}
.contenedorTextoTestimonio {
  width: 200px;
  margin-left: 10px;
  text-align: left;
}
.contenedorTextoTestimonio p {
  font-size: 18px;
}
.nombreTestimonio {
  font-size: 20px;
  font-weight: bold;
  color: #197870;
}
.tipoTestimonio {
  font-size: 16px;
}
.contenedorImagenTestimonio {
  position: relative;
}
.comillasTestimonio {
  position: absolute;
  top: -12px;
  right: 5px;
  width: 50px;
}

.fondoNegro {
  background-color: black;
}
.carrusel {
  width: 100%;
  margin: 60px auto 0 auto;
  padding-bottom: 60px;
}
.rating > span:before {
  content: "\2605";
  position: absolute;
  color: gold;
}
@media screen and (max-width: 1850px) {
  .gridTestimonios {
    width: 80%;
  }
}

@media screen and (max-width: 1300px) {
  .gridTestimonios {
    width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .tecnologia h1 {
    width: 70%;
  }
  .textosGanancias {
    padding-top: 2%;
  }
  .gridTestimonios {
    grid-template-columns: 1fr;
    width: 60%;
  }
  .testimonioUno {
    justify-self: left;
  }
  .testimonioDos {
    justify-self: right;
  }
  .testimonioTres {
    justify-self: left;
  }
}

@media screen and (max-width: 992px) {
  .tecnologia h1 {
    width: 100%;
  }
  .tecnologia {
    text-align: center;
  }
  .contenedorTecnologia {
    padding-top: 100px;
  }
  .contenedorGanancias {
    text-align: center;
  }
  .ganancias {
    width: 90%;
  }
  .ganancias img {
    width: 70%;
  }
  .contenedorBeneficios {
    padding-top: 80px;
    padding-left: 0;
    padding-right: 0;
  }
  .beneficios {
    grid-template-columns: 1fr;
  }
  .beneficios div {
    margin-bottom: 40px;
  }
  .testimonioUno,
  .testimonioDos,
  .testimonioTres {
    justify-self: center;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 576px) {
  .contenedorNav {
    padding: 10px 0;
  }
  .ganancias img {
    width: 90%;
  }
  .tecnologia h1 {
    font-size: 40px;
  }

  .testimonioUno,
  .testimonioDos,
  .testimonioTres {
    grid-template-columns: 1fr;
  }
  .contenedorTextoTestimonio {
    text-align: center;
  }
}
