/*
===============================================================================
===============================================================================   Footer
===============================================================================
*/

footer {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  background-color: black;
}

.footerContainer {
  max-width: 1400px;
  display: flex;
  flex-direction: column;
}

.boxStyles {
  display: flex;
  gap: 20px;
}

.innerFooter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
}

.AddressContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 30%;
  padding: 10px 20px 10px 20px;
}

.AddressContainer img {
  width: 200px;
}

.AddressContainer p {
  font-family: "Lato", sans-serif;
  font-weight: 400 !important;
  color: white;
  font-size: 10px;
  text-align: left;
}

.innerFooterNav {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  gap: 10px;
  padding: 10px;
}

.footerNav {
  width: 100%;
  margin-top: 4%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  gap: 25px;
  list-style-type: none;
}

.legals {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.copyright {
  padding: 0 20px 0 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.copyright span {
  font-size: 0.9em;
  color: #9f9f9f;
  font-weight: 900;
}

.copyright p {
  font-size: 10px;
  color: #9f9f9f;
}
.linkStyles {
  font-size: 10px;;
  color: #9f9f9f;
  font-weight: 300;
}

@media only screen and (max-width: 1250px) {

}

@media only screen and (max-width: 1124px) {

}

@media (max-width: 1024px) {
}

@media only screen and (max-width: 930px) {
  /* Footer */
  footer {
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
  }

  .footerContainer {
    height: 100%;
    flex-direction: column;
  }

  .AddressContainer {
    align-items: flex-start;
    width: 100%;
  }

  .AddressContainer p {
    width: 100%;
  }

  .innerFooter {
    width: 100%;
    padding: 20px;
  }
  .innerFooterNav {
    padding: 0px;
  }
  .copyright {
    padding: 20px 0 20px 0;
  }
}

@media only screen and (max-width: 680px) {
  footer {
    padding: 10px;
  }

  .footerContainer {
    height: 100%;
    flex-direction: column;
  }

  .boxStyles {
    height: 100%;
    flex-direction: column;
  }

  .AddressContainer {
    align-items: center;
    width: 100%;
  }

  .AddressContainer p {
    width: 100%;
  }

  .innerFooter {
    width: 100%;
    padding: 10px;
  }
  .innerFooterNav {
    padding: 0px;
  }
  .copyright {
    padding: 10px 0 60px 0;
  }
}

@media (max-width: 470px) {
}
