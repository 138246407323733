:root {
  --colorPrincipal: #13ffcf;
}
@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-ExtraBold.otf");
  src: local("Gilroy"), local("Gilroy"),
    url("./assets/fonts/Gilroy-ExtraBold.otf") format("opentype");
  font-weight: bold;
}
/*light version*/
@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-Light.otf");
  src: local("Gilroy"), local("Gilroy"),
    url("./assets/fonts/Gilroy-Light.otf") format("opentype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Gilroy", sans-serif;
}
.boton {
  border-radius: 5px;
  padding: 10px;
  border: none;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 15px;
}
.boton span {
  margin: 15px;
}
.botonPrincipal {
  background-color: var(--colorPrincipal);
  margin: 30px 0;
}

/* *Navegador */
.contenedorNav {
  padding: 8px 10% 0 10% !important;
  background-color: black;
}
.listaNav {
  justify-content: flex-end;
}
.nav-link {
  font-weight: bold;
  color: white;
  font-size: 15px;
}
.nav-link:hover {
  color: var(--colorPrincipal);
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-bottom: 10px !important;
  padding-top: 10px !important;
}

.botonNav {
  background-color: black;
  margin: 5px;
  border-radius: 20px;
}
.botonNav span {
  font-size: 13px;
  font-weight: lighter;
  padding: 16px;
}
.botonNavBlanco {
  border: 1px solid white;
  color: white;
  padding: 0px 10px;
}
.botonNavVerde {
  border: 1px solid var(--colorPrincipal);
  color: var(--colorPrincipal);
  padding: 0px 10px;
}
.idiomaNav {
  background-color: transparent;
  border: none;
  color: white;
  font-weight: 600;
  margin-left: 10px;
}

.idiomaNav option{
  background-color: black;
  border: none;
  color: white;
  font-weight: 600;
  margin-left: 10px;
}

.tituloPrimeraSeccion {
  margin: 100px 100px 0 100px;
  width: 40%;
}
.iconNavToggle {
  color: var(--colorPrincipal);
}
.navSecundaria {
  box-shadow: 8px 11px 52px #00000094;
}
.enlaceActivo {
  color: var(--colorPrincipal);
  border-bottom: 2px solid var(--colorPrincipal);
}
.navbar {
  padding-top: 20px;
}
select:focus,
option:focus,
option {
  outline: none !important;
  /* border: 1px solid var(--colorPrincipal); */
  /* box-shadow: 0 0 10px #719ece; */
}
.contenedorModalIniciarSesionDos {
  margin-left: 18%;
  display: none;
}

.contenedorModalIniciarSesion {
  display: block;
}
.contenedorIdiomaMovil {
  display: none;
}
.contenedorIdiomaMovil select {
  background-color: transparent;
}
.contenedorIdiomaMovil select option {
  color: black;
}
.contenedorBotonesMovil {
  display: none;
  position: -webkit-fixed; /* Safari */
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3000;
  background-color: black;
  width: 100%;
  text-align: center;
  padding: 8px 0 24px 0;
}
.navbar-brand img {
  padding-bottom: 8px;
}
.botonCuentasMovil {
  display: none;
}
.contenedorInicial {
  opacity: 0.3;
  position: absolute;
  z-index: -2000;
}

@media screen and (max-device-width: 1114px) {
  .contenedorNav {
    padding: 15px 20px 0 20px !important;
  }
  .navbar-brand img {
    padding-bottom: 8px;
  }
}
@media screen and (max-device-width: 992px) {
  .contenedorModalIniciarSesionDos {
    display: block;
  }
  .contenedorModalIniciarSesion {
    display: none;
  }
  .botonCuentasEscritorio {
    display: none;
  }
  .botonCuentasMovil {
    display: block;
    margin: auto;
  }
}
@media screen and (max-device-width: 790px) {
  .contenedorModalIniciarSesionDos {
    margin-left: 2%;
  }
}
@media screen and (max-device-width: 680px) {
  .navbar-brand img {
    width: 110px;
  }
}
@media screen and (max-device-width: 576px) {
  .contenedorModalIniciarSesionDos {
    display: none;
  }
  .boton span {
    margin: 0;
  }
  .contenedorIdiomaMovil {
    display: block;
    margin-left: 20%;
  }
  .contenedorBotonesMovil {
    display: block;
  }
}
@media screen and (max-device-width: 450px) {
  .contenedorIdiomaMovil {
    display: block;
    margin-left: 5%;
  }
  .navbar-brand img {
    width: 150px;
  }
}
