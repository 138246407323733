* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Gilroy", sans-serif;
}
.cabeceraQuienes {
  text-align: center;
  width: 80%;
  margin: 150px auto 150px auto;
}

.tituloQuienesTitulo {
  font-weight: bold;
  font-size: 56px;
}
.pilares {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.vision img,
.valores img {
  padding-top: 24px;
}
.pilaresSubtitulo {
  text-transform: uppercase;
  color: black;
  font-size: 32px;
  font-weight: bold;
  margin-top: 8px;
}
.mision .pilaresSubtitulo {
  margin-left: -20px;
}
.valores p,
.mision p,
.vision p {
  margin: 8px 8px;
}
.labor {
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 70%;
  margin: 40px auto;
  padding-bottom: 80px;
}
.contenedorLabor {
  position: relative;
  z-index: 5;
}
.contenedorLabor::after {
  content: "";
  clip-path: polygon(0 0, 100% 10%, 100% 89%, 0% 100%);
  -webkit-clip-path: polygon(0 0, 100% 10%, 100% 89%, 0% 100%);
  background-color: black;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: -3;
}

.parrafosLabor {
  max-width: 600px;
}

.parrafosLaborTitulo {
  font-size: 56px;
  font-weight: bold;
  color: rgb(255, 255, 255);
  margin-top: 100px;
}
.parrafosLaborSubtitulo {
  font-size: 24px;
  color: var(--colorPrincipal);
}
.parrafosLabor p {
  font-size: 16px;
  color: #eef9f8;
  font-weight: 400;
}
.imagenesLabor {
  background-image: url(../../assets/images/rueda.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  position: relative;
}
.imagenesLabor img {
  position: absolute;
  z-index: 10;
  transition: all 0.3s;
}
.imagenLaborUno {
  width: 28%;
  top: 15%;
  right: 20%;
}
.imagenLaborDos {
  width: 35%;
  bottom: 10%;
  right: 25%;
}
.imagenLaborTres {
  top: 35%;
  left: 20%;
}
.imagenesLabor img:hover {
  transform: scale(1.3);
  z-index: 12;
}
.contenedorPlataforma {
  background-color: #f1f1f1;
  padding-top: 220px;
  margin-top: -200px;
  padding-bottom: 300px;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 89%);
}
.plataforma {
  text-align: center;

  width: 80%;
  margin: 10px auto;
}
.plataformaTitulo {
  font-size: 48px;
  font-weight: bold;
}
.plataforma p {
  font-size: 20px;
  font-weight: lighter;
  width: 60%;
  margin: 30px auto;
}
.plataformaSubtitulo {
  font-size: 20px;
  font-weight: bold;
}
.contenedorIconosPlataforma {
  margin: 40px auto;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.contenedorIconosPlataforma span img {
  margin-top: 20px;
  height: 50px;
  margin-bottom: 8px;
}
.contenedorIconosSub {
  font-size: 15px;
  text-transform: uppercase;
}
.contenedorFormularioQuienes {
  width: 80%;
  margin: -200px auto 150px auto;
  position: relative;
  z-index: 100;
}
.formularioQuienes {
  width: 80%;
  margin: 10px auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.contenedorBotonQuienes {
  max-width: 400px;
}
.contenedorBotonQuienesSubtitulo {
  font-size: 48px;
  font-weight: bold;
  color: black;
}
.botonAyudarte {
  border: 1px solid #111111;
  font-size: 15px;
  box-shadow: 2px 3px 16px #1b1f7338;
}
.contenedorSolicitud {
  background-image: url(../../assets/images/rueda.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
}
.contenedorSolicitud form {
  width: 70%;
  margin: 10px 10% 10px 20%;
  background-color: white;
  box-shadow: 2px 3px 16px #1b1f7338;
  border-radius: 5px;
}
.contenedorSolicitud form input {
  width: 90%;
  margin-left: 5%;
  margin-bottom: 15px;
  border: 2px solid #b7b7b7;
  border-radius: 5px;
  padding: 5px;
}
.contenedorSolicitud form input:last-of-type {
  height: 100px;
}
.contenedorSolicitud form label {
  font-size: 12px;
  color: black;
  width: 90%;
  margin-left: 5%;
}
.contenedorSolicitud form label:first-of-type {
  margin-top: 20px;
}
.botonEnviarMensaje {
  margin-bottom: 30px;
  width: 90%;
  margin-left: 5%;
  background-color: black;
}
.botonEnviarMensaje span {
  color: var(--colorPrincipal);
  font-weight: bold;
}
.quienesSubtitulo {
  font-size: 32px;
  font-weight: lighter;
}

@media screen and (min-width: 1900px) {
  .contenedorIconosPlataforma {
    width: 60%;
  }
  .contenedorBotonQuienes {
    margin-top: 100px;
    margin-left: 40%;
  }
}
@media screen and (max-width: 1850px) {
  .labor {
    width: 80%;
  }
}

@media screen and (max-width: 1300px) {
  .imagenLaborUno {
    width: 50%;
    top: 15%;
    right: 20%;
  }
  .imagenLaborDos {
    width: 50%;
    bottom: 15%;
    right: 22%;
  }
  .imagenLaborTres {
    width: 50%;
    top: 35%;
    left: 20%;
  }
}
@media screen and (max-width: 1200px) {
  .imagenesLabor img {
    position: relative;
    margin-left: 30px;
    margin-top: 20px;
  }
  .imagenLaborDos {
    width: 70%;
    bottom: 0;
    right: 0;
  }
  .imagenLaborTres,
  .imagenLaborUno {
    display: none;
  }
  .labor {
    grid-template-columns: 1fr;
  }
  .parrafosLabor {
    margin-left: 30px;
  }
  .imagenesLabor {
    background-position: center;
    background-size: contain;
    padding-bottom: 120px;
    margin-bottom: -80px;
  }
}
@media (max-width: 992px) {
  .contenedorIconosPlataforma {
    grid-template-columns: repeat(4, 1fr);
  }
  .plataforma p {
    width: 90%;
  }
  .formularioQuienes {
    grid-template-columns: 1fr;
  }
  .contenedorBotonQuienes {
    text-align: center;
    margin: 10px auto;
  }
  .contenedorSolicitud form {
    width: 80%;
    margin: 10px auto 10px auto;
  }
}

@media screen and (max-width: 576px) {
  .pilares {
    grid-template-columns: 1fr;
  }
  .tituloQuienesTitulo {
    font-size: 48px;
  }
  .cabeceraQuienes {
    width: 90%;
  }
  .labor {
    width: 90%;
  }
  .plataforma {
    width: 90%;
  }
  .contenedorIconosPlataforma {
    grid-template-columns: repeat(2, 1fr);
  }
  .contenedorSolicitud form {
    width: 100%;
    margin: 10px auto 10px auto;
  }
  .contenedorFormularioQuienes {
    width: 100%;
  }
}
@media screen and (max-width: 425px) {
  .parrafosLaborTitulo {
    font-size: 48px;
  }
}
