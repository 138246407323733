@import url("https://fonts.googleapis.com/css?family=Lato|Titillium+Web&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.primeraSeccion {
  background-color: white;
  width: 100%;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    ),
    url(../assets/images/fondoHeader.png);

  background-size: 100%;
  background-repeat: no-repeat;
  grid-area: primeraSeccion;
  background-position: center;
  height: 630px;
  display: grid;
  grid-template-areas: "contenedorListaImagen";
}

.contenedorListaImagen {
  padding-top: 120px;
  width: 70%;
  margin: 20px auto;
  display: grid;
  grid-template-areas:
    "titulo imagen"
    "lista imagen";
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr 3fr;
}

.titulo {
  grid-area: titulo;
  font-family: "Titillium Web", sans-serif;
  font-weight: 300;
  padding: 20px;
}

.tituloInicial {
  font-size: 35px !important;
}

.lista {
  grid-area: lista;
  position: relative;
}

.lista ul {
  list-style: none;
  position: absolute;
  top: -30px;
}

.lista ul li {
  text-transform: uppercase;
  display: block;
  background-color: #eaf8f6;
  margin: 15px auto;
  font-family: "Titillium Web", sans-serif;
  font-weight: bolder;
  position: relative;
  font-size: 15px;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px 35px 5px 10px;
}

.lista ul li:hover,
.activePoliticas {
  background-color: var(--colorPrincipal) !important;
  color: black;
}

.lista ul li span {
  position: absolute;
  right: 10px;
  padding-left: 30px;
}

.imagen {
  grid-area: imagen;
  text-align: center;
  margin-top: 100px;
}

.imagen img {
  width: 550px;
}

/* Auxiliar */
.rotar {
  -moz-transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
  transition: all 0.15s linear;
}

.rotar.down {
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.segundaSeccion {
  width: 70%;
  margin: 60px auto;
}

.segundaSeccion article {
  white-space: pre-line;
  font-family: "Lato", sans-serif;
}

.segundaSeccion article p {
  font-size: 12px;
}

.articuloUno,
.articuloDos,
.articuloTres,
.articuloCuatro,
.articuloCinco,
.articuloSeis,
.articuloSiete,
.articuloOcho,
.articuloNueve
.articuloDiez {
  display: none;
  margin-top: 10px;
}

.tituloArticulos {
  font-size: 56px;
  font-weight: bold;
}
@media (min-width: 1700px) {
  .primeraSeccion {
    height: 800px;
  }

  .contenedorListaImagen {
    grid-template-columns: 35% 65%;
  }

  .lista ul li {
    margin: 10px auto;
    padding: 10px 35px 15px 50px;
    font-size: 15px;
  }

  .tituloInicial {
    font-size: 50px;
  }
  .imagen img {
    width: 400px;
  }
}

@media (max-width: 1480px) {
.segundaSeccion {
  width: 70%;
  margin: 160px auto;
}
}

@media (max-width: 1300px) {
  .lista ul li {
    margin: 5px auto;
    padding: 5px 35px 5px 8px;
    font-size: 13px;
  }
.segundaSeccion {
  width: 70%;
  margin: 100px auto;
}
  .tituloInicial {
    font-size: 35px;
  }
}

@media (max-width: 930px) {
  .contenedorListaImagen {
    width: 90%;
  }

  .lista ul li {
    padding: 5px 25px 5px 8px;
    font-size: 12px;
  }

  .tituloInicial {
    font-size: 27px;
  }

  .imagen {
    margin-top: 30px;
  }
}

@media (max-width: 768px) {
  .contenedorListaImagen {
    display: grid;
    grid-template-areas:
      "imagen"
      "titulo"
      "lista";
    grid-template-columns: 1fr;
    grid-template-rows: 10% 35% 90%;
  }

  .tituloInicial {
    text-align: center;
  }

  .imagen img {
    width: 100px;
    display: none;
  }

  .imagen {
    margin-top: 0;
  }

  .lista ul {
    list-style: none;
    position: relative;
    margin: 10 auto 0 auto;
  }

  .segundaSeccion {
    margin: 50px auto 0;
    width: 90%;
  }
}

@media (max-width: 400px) {
  .contenedorListaImagen {
    padding-top: 20px;
    display: grid;
    grid-template-areas:
      "imagen"
      "titulo"
      "lista";
    grid-template-columns: 1fr;
    grid-template-rows: 5% 15% 60%;
  }

  .imagen img {
    width: 150px;
    display: none;
  }
}
