@import url(https://fonts.googleapis.com/css?family=Lato|Titillium+Web&display=swap);
:root {
  --colorPrincipal: #13ffcf;
}
@font-face {
  font-family: "Gilroy";
  src: url(/static/media/Gilroy-ExtraBold.0f6e082f.otf);
  src: local("Gilroy"), local("Gilroy"),
    url(/static/media/Gilroy-ExtraBold.0f6e082f.otf) format("opentype");
  font-weight: bold;
}
/*light version*/
@font-face {
  font-family: "Gilroy";
  src: url(/static/media/Gilroy-Light.c62aded7.otf);
  src: local("Gilroy"), local("Gilroy"),
    url(/static/media/Gilroy-Light.c62aded7.otf) format("opentype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Gilroy", sans-serif;
}
.boton {
  border-radius: 5px;
  padding: 10px;
  border: none;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 15px;
}
.boton span {
  margin: 15px;
}
.botonPrincipal {
  background-color: #13ffcf;
  background-color: var(--colorPrincipal);
  margin: 30px 0;
}

/* *Navegador */
.contenedorNav {
  padding: 8px 10% 0 10% !important;
  background-color: black;
}
.listaNav {
  justify-content: flex-end;
}
.nav-link {
  font-weight: bold;
  color: white;
  font-size: 15px;
}
.nav-link:hover {
  color: #13ffcf;
  color: var(--colorPrincipal);
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-bottom: 10px !important;
  padding-top: 10px !important;
}

.botonNav {
  background-color: black;
  margin: 5px;
  border-radius: 20px;
}
.botonNav span {
  font-size: 13px;
  font-weight: lighter;
  padding: 16px;
}
.botonNavBlanco {
  border: 1px solid white;
  color: white;
  padding: 0px 10px;
}
.botonNavVerde {
  border: 1px solid #13ffcf;
  border: 1px solid var(--colorPrincipal);
  color: #13ffcf;
  color: var(--colorPrincipal);
  padding: 0px 10px;
}
.idiomaNav {
  background-color: transparent;
  border: none;
  color: white;
  font-weight: 600;
  margin-left: 10px;
}

.idiomaNav option{
  background-color: black;
  border: none;
  color: white;
  font-weight: 600;
  margin-left: 10px;
}

.tituloPrimeraSeccion {
  margin: 100px 100px 0 100px;
  width: 40%;
}
.iconNavToggle {
  color: #13ffcf;
  color: var(--colorPrincipal);
}
.navSecundaria {
  box-shadow: 8px 11px 52px #00000094;
}
.enlaceActivo {
  color: #13ffcf;
  color: var(--colorPrincipal);
  border-bottom: 2px solid #13ffcf;
  border-bottom: 2px solid var(--colorPrincipal);
}
.navbar {
  padding-top: 20px;
}
select:focus,
option:focus,
option {
  outline: none !important;
  /* border: 1px solid var(--colorPrincipal); */
  /* box-shadow: 0 0 10px #719ece; */
}
.contenedorModalIniciarSesionDos {
  margin-left: 18%;
  display: none;
}

.contenedorModalIniciarSesion {
  display: block;
}
.contenedorIdiomaMovil {
  display: none;
}
.contenedorIdiomaMovil select {
  background-color: transparent;
}
.contenedorIdiomaMovil select option {
  color: black;
}
.contenedorBotonesMovil {
  display: none;
  position: -webkit-fixed; /* Safari */
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3000;
  background-color: black;
  width: 100%;
  text-align: center;
  padding: 8px 0 24px 0;
}
.navbar-brand img {
  padding-bottom: 8px;
}
.botonCuentasMovil {
  display: none;
}
.contenedorInicial {
  opacity: 0.3;
  position: absolute;
  z-index: -2000;
}

@media screen and (max-device-width: 1114px) {
  .contenedorNav {
    padding: 15px 20px 0 20px !important;
  }
  .navbar-brand img {
    padding-bottom: 8px;
  }
}
@media screen and (max-device-width: 992px) {
  .contenedorModalIniciarSesionDos {
    display: block;
  }
  .contenedorModalIniciarSesion {
    display: none;
  }
  .botonCuentasEscritorio {
    display: none;
  }
  .botonCuentasMovil {
    display: block;
    margin: auto;
  }
}
@media screen and (max-device-width: 790px) {
  .contenedorModalIniciarSesionDos {
    margin-left: 2%;
  }
}
@media screen and (max-device-width: 680px) {
  .navbar-brand img {
    width: 110px;
  }
}
@media screen and (max-device-width: 576px) {
  .contenedorModalIniciarSesionDos {
    display: none;
  }
  .boton span {
    margin: 0;
  }
  .contenedorIdiomaMovil {
    display: block;
    margin-left: 20%;
  }
  .contenedorBotonesMovil {
    display: block;
  }
}
@media screen and (max-device-width: 450px) {
  .contenedorIdiomaMovil {
    display: block;
    margin-left: 5%;
  }
  .navbar-brand img {
    width: 150px;
  }
}

/*
===============================================================================
===============================================================================   Footer
===============================================================================
*/

footer {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  background-color: black;
}

.footerContainer {
  max-width: 1400px;
  display: flex;
  flex-direction: column;
}

.boxStyles {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}

.innerFooter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
}

.AddressContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 30%;
  padding: 10px 20px 10px 20px;
}

.AddressContainer img {
  width: 200px;
}

.AddressContainer p {
  font-family: "Lato", sans-serif;
  font-weight: 400 !important;
  color: white;
  font-size: 10px;
  text-align: left;
}

.innerFooterNav {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  grid-gap: 10px;
  gap: 10px;
  padding: 10px;
}

.footerNav {
  width: 100%;
  margin-top: 4%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  grid-gap: 25px;
  gap: 25px;
  list-style-type: none;
}

.legals {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
}

.copyright {
  padding: 0 20px 0 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.copyright span {
  font-size: 0.9em;
  color: #9f9f9f;
  font-weight: 900;
}

.copyright p {
  font-size: 10px;
  color: #9f9f9f;
}
.linkStyles {
  font-size: 10px;;
  color: #9f9f9f;
  font-weight: 300;
}

@media only screen and (max-width: 1250px) {

}

@media only screen and (max-width: 1124px) {

}

@media (max-width: 1024px) {
}

@media only screen and (max-width: 930px) {
  /* Footer */
  footer {
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
  }

  .footerContainer {
    height: 100%;
    flex-direction: column;
  }

  .AddressContainer {
    align-items: flex-start;
    width: 100%;
  }

  .AddressContainer p {
    width: 100%;
  }

  .innerFooter {
    width: 100%;
    padding: 20px;
  }
  .innerFooterNav {
    padding: 0px;
  }
  .copyright {
    padding: 20px 0 20px 0;
  }
}

@media only screen and (max-width: 680px) {
  footer {
    padding: 10px;
  }

  .footerContainer {
    height: 100%;
    flex-direction: column;
  }

  .boxStyles {
    height: 100%;
    flex-direction: column;
  }

  .AddressContainer {
    align-items: center;
    width: 100%;
  }

  .AddressContainer p {
    width: 100%;
  }

  .innerFooter {
    width: 100%;
    padding: 10px;
  }
  .innerFooterNav {
    padding: 0px;
  }
  .copyright {
    padding: 10px 0 60px 0;
  }
}

@media (max-width: 470px) {
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navInicio {
  background-color: transparent;
}
.cabeceraInicio {
  background-size: cover;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.253),
      rgb(0, 0, 0)
    ),
    url(/static/media/bg.999475a1.webp);
  background-position: top;
}
.contenedorTecnologia {
  padding-top: 200px;
  width: 80%;
  margin: 0 auto;
  position: relative;
}
.ruedaInicio {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
}

.tecnologia h1 {
  font-size: 56px;
  font-weight: bold;
  color: white;
  width: 50%;
}
.contenedorGanancias {
  position: relative;
  margin-top: -3px;
}
.contenedorGanancias:after {
  width: 100%;
  height: 100%;
  content: " ";
  top: 0;
  left: 0;
  background: black;
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 69%, 0% 100%);
          clip-path: polygon(0 0, 100% 0%, 100% 69%, 0% 100%);
  position: absolute;
  z-index: -1;
}
.ganancias {
  padding-top: 60px;
  padding-bottom: 80px;
  width: 80%;
  margin: 0 auto;
}

.textosGanancias {
  padding-top: 8%;
}

.textosGananciasTitulo {
  font-size: 48px;
  font-weight: bold;
  color: white;
}

.ganancias p {
  font-size: 20px;
  letter-spacing: 1px;
  color: #ffffff;
  opacity: 1;
}

.ganancias button {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 9px 16px #e54f3e38;

  border-radius: 5px;
  opacity: 1;
}

.ganancias img {
  box-shadow: 0px 10px 20px #00000096;
  opacity: 1;
  width: 100%;
}

.contenedorTituloBeneficios {
  padding-top: 250px;
  background-color: #f9f9f9;
  margin: -150px auto 30px;
  position: relative;
  z-index: -2;
  width: 80%;
  text-align: center;
}

.contenedorTituloBeneficiosTitulo {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 50px;
}
.beneficios {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.tituloBeneficio {
  margin: 5px 0;
  font-size: 24px;
  font-weight: bold;
}
.beneficios div p {
  margin: 5px;
  font-size: 24px;
}
.contenedorTestimonios {
  margin-top: 200px;
  margin-bottom: 200px;
}
.testimonios {
  margin: 20px auto;
  text-align: center;
}
.testimonioTitulo {
  font-size: 48px;
  font-weight: bold;
}
.testimoniosSubtitulo {
  font-size: 20px;
  width: 50%;
  margin: auto;
}
.gridTestimonios {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  margin: 50px auto 20px auto;
  width: 70%;
}
.imagenTestimonio {
  width: 150px;
}
.testimonioUno,
.testimonioDos,
.testimonioTres {
  display: grid;
  grid-template-columns: auto 1fr;
}
.contenedorTextoTestimonio {
  width: 200px;
  margin-left: 10px;
  text-align: left;
}
.contenedorTextoTestimonio p {
  font-size: 18px;
}
.nombreTestimonio {
  font-size: 20px;
  font-weight: bold;
  color: #197870;
}
.tipoTestimonio {
  font-size: 16px;
}
.contenedorImagenTestimonio {
  position: relative;
}
.comillasTestimonio {
  position: absolute;
  top: -12px;
  right: 5px;
  width: 50px;
}

.fondoNegro {
  background-color: black;
}
.carrusel {
  width: 100%;
  margin: 60px auto 0 auto;
  padding-bottom: 60px;
}
.rating > span:before {
  content: "\2605";
  position: absolute;
  color: gold;
}
@media screen and (max-width: 1850px) {
  .gridTestimonios {
    width: 80%;
  }
}

@media screen and (max-width: 1300px) {
  .gridTestimonios {
    width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .tecnologia h1 {
    width: 70%;
  }
  .textosGanancias {
    padding-top: 2%;
  }
  .gridTestimonios {
    grid-template-columns: 1fr;
    width: 60%;
  }
  .testimonioUno {
    justify-self: left;
  }
  .testimonioDos {
    justify-self: right;
  }
  .testimonioTres {
    justify-self: left;
  }
}

@media screen and (max-width: 992px) {
  .tecnologia h1 {
    width: 100%;
  }
  .tecnologia {
    text-align: center;
  }
  .contenedorTecnologia {
    padding-top: 100px;
  }
  .contenedorGanancias {
    text-align: center;
  }
  .ganancias {
    width: 90%;
  }
  .ganancias img {
    width: 70%;
  }
  .contenedorBeneficios {
    padding-top: 80px;
    padding-left: 0;
    padding-right: 0;
  }
  .beneficios {
    grid-template-columns: 1fr;
  }
  .beneficios div {
    margin-bottom: 40px;
  }
  .testimonioUno,
  .testimonioDos,
  .testimonioTres {
    justify-self: center;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 576px) {
  .contenedorNav {
    padding: 10px 0;
  }
  .ganancias img {
    width: 90%;
  }
  .tecnologia h1 {
    font-size: 40px;
  }

  .testimonioUno,
  .testimonioDos,
  .testimonioTres {
    grid-template-columns: 1fr;
  }
  .contenedorTextoTestimonio {
    text-align: center;
  }
}

.modal-body form {
  display: flex;
  flex-direction: column;
  padding: 1rem !important;
  flex-wrap: wrap;
}

.modal-body form p {
  margin: 10px 0px 20px 0px !important;
}
.modal-body form input[type="text"],
.modal-body form input[type="password"] {
  border: 1px solid grey;
  border-radius: 3px;
  height: 35px;
  padding-left: 10px;
}

.modal form label {
  margin-top: 15px;
}
.modal-content .boton {
  width: 100%;
  text-transform: uppercase;
}

.modal-body form #btnFilled {
  width: 100%;
  text-transform: uppercase;
  margin-top: 20px;
}

.modal-body {
  padding: 0rem 1rem 1rem 1rem !important;
}

.modal-header {
  border: none !important;
  padding: 1rem 1rem 0rem 1rem !important;
  display: flex;
  align-items: center !important;
}

.modal-header .close {
  margin: 0px 20px 0px 0px !important;
  padding: 0px !important;
  font-size: 2rem;
}

#Cargando {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.63);
  position: absolute;
  margin: -16px;
  z-index: 1;
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  margin-top: -140px;
}

#Cargando2 {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.63);
  position: absolute;
  z-index: 1;
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
}

#mensajeError,
#mensajeError2 {
  text-align: left;
  margin-top: 30px;
  text-transform: uppercase;
  color: rgb(249, 35, 35);
  font-size: 0.8em;
}

.innerModalBody {
  padding: 0rem 1rem 1rem 1rem !important;
}

.innerModalBody p {
  color: black !important;
  font-weight: 800 !important;
}

.innerModalBody #btnVoid {
  width: 100%;
  height: 45px;
  text-transform: uppercase;
  text-decoration: none;
  margin-top: 10px;
}

.modal-title p {
  padding: 1rem 1rem 0rem 1rem !important;
  font-weight: bold;
  font-size: 40px;
}

#formularioSignIn {
  display: flex;
  flex-direction: column;
  padding: 0 40px 40px 40px;
  padding-bottom: 10px;
}

#formularioSignIn #btnFilled {
  margin: 20px 0px;
  text-transform: uppercase !important;
}

.formularioSignInSub {
  font-size: 2.5rem;
  margin-top: -30px;
  text-align: left;
  font-weight: bold;
}

.innerModalBody p {
  display: flex;
}

.innerModalBody button:hover,
.innerModalBody button:focus,
.innerModalBody button:active {
  background-color: var(--colorPrincipal);
  color: black;
  text-decoration: underline;
  position: relative;
}

#formularioSignIn input[type="text"],
#formularioSignIn input[type="password"],
#formularioSignIn input[type="email"] {
  border: 1px solid grey !important;
  border-radius: 3px !important;
  height: 35px !important;
  padding-left: 10px !important;
}

.sliderUnitario {
  text-align: center;
  transition: all 0.3s ease-in;
}
.sliderUnitario:focus {
  outline-color: var(--colorPrincipal);
}
.sliderUnitario img {
  width: 100px;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  margin: auto;
}
.precioElemento {
  color: white;
  font-weight: bold;
  font-size: 40px;
}
.nombreElemento {
  color: #13ffcf;
  font-size: 18px;
  display: inline-block;
}
.cambioElemento {
  display: inline-block;
  font-size: 16px;
  margin-left: 25px;
}
.precioVerde {
  color: #13ff23;
}
.precioRojo {
  color: rgb(255, 19, 19);
}

.btn-social {
  position: fixed;
  display: block;
  width: 40px;
  height: 40px;
  margin: 0;
  bottom: 20px;
  right: 15px;
  z-index: 5;
}

.btn-social__icon {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media screen and (max-width: 576px) {
  .btn-social {
    bottom: 85px;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Gilroy", sans-serif;
}
.cabeceraQuienes {
  text-align: center;
  width: 80%;
  margin: 150px auto 150px auto;
}

.tituloQuienesTitulo {
  font-weight: bold;
  font-size: 56px;
}
.pilares {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.vision img,
.valores img {
  padding-top: 24px;
}
.pilaresSubtitulo {
  text-transform: uppercase;
  color: black;
  font-size: 32px;
  font-weight: bold;
  margin-top: 8px;
}
.mision .pilaresSubtitulo {
  margin-left: -20px;
}
.valores p,
.mision p,
.vision p {
  margin: 8px 8px;
}
.labor {
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 70%;
  margin: 40px auto;
  padding-bottom: 80px;
}
.contenedorLabor {
  position: relative;
  z-index: 5;
}
.contenedorLabor::after {
  content: "";
  clip-path: polygon(0 0, 100% 10%, 100% 89%, 0% 100%);
  -webkit-clip-path: polygon(0 0, 100% 10%, 100% 89%, 0% 100%);
  background-color: black;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: -3;
}

.parrafosLabor {
  max-width: 600px;
}

.parrafosLaborTitulo {
  font-size: 56px;
  font-weight: bold;
  color: rgb(255, 255, 255);
  margin-top: 100px;
}
.parrafosLaborSubtitulo {
  font-size: 24px;
  color: var(--colorPrincipal);
}
.parrafosLabor p {
  font-size: 16px;
  color: #eef9f8;
  font-weight: 400;
}
.imagenesLabor {
  background-image: url(/static/media/rueda.283f5415.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  position: relative;
}
.imagenesLabor img {
  position: absolute;
  z-index: 10;
  transition: all 0.3s;
}
.imagenLaborUno {
  width: 28%;
  top: 15%;
  right: 20%;
}
.imagenLaborDos {
  width: 35%;
  bottom: 10%;
  right: 25%;
}
.imagenLaborTres {
  top: 35%;
  left: 20%;
}
.imagenesLabor img:hover {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
  z-index: 12;
}
.contenedorPlataforma {
  background-color: #f1f1f1;
  padding-top: 220px;
  margin-top: -200px;
  padding-bottom: 300px;
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 89%);
          clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 89%);
}
.plataforma {
  text-align: center;

  width: 80%;
  margin: 10px auto;
}
.plataformaTitulo {
  font-size: 48px;
  font-weight: bold;
}
.plataforma p {
  font-size: 20px;
  font-weight: lighter;
  width: 60%;
  margin: 30px auto;
}
.plataformaSubtitulo {
  font-size: 20px;
  font-weight: bold;
}
.contenedorIconosPlataforma {
  margin: 40px auto;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.contenedorIconosPlataforma span img {
  margin-top: 20px;
  height: 50px;
  margin-bottom: 8px;
}
.contenedorIconosSub {
  font-size: 15px;
  text-transform: uppercase;
}
.contenedorFormularioQuienes {
  width: 80%;
  margin: -200px auto 150px auto;
  position: relative;
  z-index: 100;
}
.formularioQuienes {
  width: 80%;
  margin: 10px auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.contenedorBotonQuienes {
  max-width: 400px;
}
.contenedorBotonQuienesSubtitulo {
  font-size: 48px;
  font-weight: bold;
  color: black;
}
.botonAyudarte {
  border: 1px solid #111111;
  font-size: 15px;
  box-shadow: 2px 3px 16px #1b1f7338;
}
.contenedorSolicitud {
  background-image: url(/static/media/rueda.283f5415.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
}
.contenedorSolicitud form {
  width: 70%;
  margin: 10px 10% 10px 20%;
  background-color: white;
  box-shadow: 2px 3px 16px #1b1f7338;
  border-radius: 5px;
}
.contenedorSolicitud form input {
  width: 90%;
  margin-left: 5%;
  margin-bottom: 15px;
  border: 2px solid #b7b7b7;
  border-radius: 5px;
  padding: 5px;
}
.contenedorSolicitud form input:last-of-type {
  height: 100px;
}
.contenedorSolicitud form label {
  font-size: 12px;
  color: black;
  width: 90%;
  margin-left: 5%;
}
.contenedorSolicitud form label:first-of-type {
  margin-top: 20px;
}
.botonEnviarMensaje {
  margin-bottom: 30px;
  width: 90%;
  margin-left: 5%;
  background-color: black;
}
.botonEnviarMensaje span {
  color: var(--colorPrincipal);
  font-weight: bold;
}
.quienesSubtitulo {
  font-size: 32px;
  font-weight: lighter;
}

@media screen and (min-width: 1900px) {
  .contenedorIconosPlataforma {
    width: 60%;
  }
  .contenedorBotonQuienes {
    margin-top: 100px;
    margin-left: 40%;
  }
}
@media screen and (max-width: 1850px) {
  .labor {
    width: 80%;
  }
}

@media screen and (max-width: 1300px) {
  .imagenLaborUno {
    width: 50%;
    top: 15%;
    right: 20%;
  }
  .imagenLaborDos {
    width: 50%;
    bottom: 15%;
    right: 22%;
  }
  .imagenLaborTres {
    width: 50%;
    top: 35%;
    left: 20%;
  }
}
@media screen and (max-width: 1200px) {
  .imagenesLabor img {
    position: relative;
    margin-left: 30px;
    margin-top: 20px;
  }
  .imagenLaborDos {
    width: 70%;
    bottom: 0;
    right: 0;
  }
  .imagenLaborTres,
  .imagenLaborUno {
    display: none;
  }
  .labor {
    grid-template-columns: 1fr;
  }
  .parrafosLabor {
    margin-left: 30px;
  }
  .imagenesLabor {
    background-position: center;
    background-size: contain;
    padding-bottom: 120px;
    margin-bottom: -80px;
  }
}
@media (max-width: 992px) {
  .contenedorIconosPlataforma {
    grid-template-columns: repeat(4, 1fr);
  }
  .plataforma p {
    width: 90%;
  }
  .formularioQuienes {
    grid-template-columns: 1fr;
  }
  .contenedorBotonQuienes {
    text-align: center;
    margin: 10px auto;
  }
  .contenedorSolicitud form {
    width: 80%;
    margin: 10px auto 10px auto;
  }
}

@media screen and (max-width: 576px) {
  .pilares {
    grid-template-columns: 1fr;
  }
  .tituloQuienesTitulo {
    font-size: 48px;
  }
  .cabeceraQuienes {
    width: 90%;
  }
  .labor {
    width: 90%;
  }
  .plataforma {
    width: 90%;
  }
  .contenedorIconosPlataforma {
    grid-template-columns: repeat(2, 1fr);
  }
  .contenedorSolicitud form {
    width: 100%;
    margin: 10px auto 10px auto;
  }
  .contenedorFormularioQuienes {
    width: 100%;
  }
}
@media screen and (max-width: 425px) {
  .parrafosLaborTitulo {
    font-size: 48px;
  }
}

.contacto {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding-bottom: 56px;
}

.imagenContacto img {
  width: 80%;
  margin: 0 10%;
}
.contenedorContactanos {
  padding-top: 100px;
  width: 90%;
  margin: 0 auto;
}

.tituloContacto {
  font-size: 48px;
  font-weight: bold;
  color: white;
  margin-top: 40px;
}
.contacto p {
  font-size: 20px;
  color: white;
  font-weight: lighter;
}
.contacto span {
  color: white;
  display: block;
  padding-bottom: 10px;
  margin: 10px 0;
  border-bottom: 1px solid white;
  width: 70%;
}
.contacto span:last-of-type {
  border-bottom: none;
}
.contenedorRevolucion {
  position: relative;
  color: white;
  margin-top: -10px;
  background-color: black;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 84%, 0% 100%);
          clip-path: polygon(0 0, 100% 0, 100% 84%, 0% 100%);
  margin-bottom: 200px;
}
.revolucion {
  display: grid;
  grid-template-columns: 2fr 1fr;
  width: 80%;
  margin: 0 auto;
  padding-bottom: 20px;
}

.imagenRevolucion {
  text-align: center;
}
.imagenRevolucion img {
  width: 100%;
}

.textoRevolucionTitulo {
  font-size: 48px;
  font-weight: bold;
}
.textoRevolucion p {
  font-size: 20px;
  font-weight: lighter;
}
.contenedorTextoRevolucion {
  margin-top: 40%;
}
.areaContacto {
  align-self: center;
  margin-left: 20%;
}
.botonContactanos {
  margin: 20px 0;
}
@media screen and (max-width: 1480px) {
  .contenedorTextoRevolucion {
    margin-top: 20%;
  }
}
@media screen and (max-width: 1300px) {
  .contenedorTextoRevolucion {
    margin-top: 12%;
  }
}
@media screen and (max-width: 1200px) {
  .areaContacto {
    margin-left: 0;
  }
  .contenedorRevolucion {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
  .revolucion {
    padding-bottom: 0px;
    grid-gap: 10px;
  }
}
@media screen and (max-width: 992px) {
  .contacto,
  .revolucion {
    grid-template-columns: 1fr;
    grid-template-areas: "imagenContacto" "areaContacto";
  }
  .areaContacto {
    grid-area: areaContacto;
    text-align: center;
  }
  .imagenContacto {
    grid-area: imagenContacto;
  }
  .contacto span {
    margin: 10px auto;
    width: 70%;
  }
  .imagenRevolucion img {
    width: 80%;
  }
  .contenedorRevolucion {
    padding-bottom: 200px;
  }
  .textoRevolucion {
    text-align: center;
  }
}

@media screen and (max-width: 576px) {
}

@font-face {
  font-family: "Gilroy";
  src: url(/static/media/Gilroy-ExtraBold.0f6e082f.otf);
  src: local("Gilroy"), local("Gilroy"),
    url(/static/media/Gilroy-ExtraBold.0f6e082f.otf) format("opentype");
  font-weight: bold;
}
/*light version*/
@font-face {
  font-family: "Gilroy";
  src: url(/static/media/Gilroy-Light.c62aded7.otf);
  src: local("Gilroy"), local("Gilroy"),
    url(/static/media/Gilroy-Light.c62aded7.otf) format("opentype");
}

.invierte {
  display: grid;
  grid-template-columns: 40% 60%;
  width: 70%;
  margin: -180px auto 20px auto;
}
.imagenCompu {
  background-image: url(/static/media/rueda.283f5415.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: relative;
  text-align: center;
  overflow: visible;
  padding: 200px 0;
}
.imagenCompu img {
  width: 70%;
}
.liderMundialTitulo {
  font-size: 56px;
  font-weight: bold;
  padding-top: 200px;
  line-height: 1.2;
}
.liderMundial p {
  font-size: 24px;
}
.botonContacto {
  margin: 5px 0;
}
.contenedorCombinacion {
  text-align: center;
  width: 70%;
  margin: -20px auto 0 auto;
  position: relative;
  z-index: 3;
}
.contenedorCombinacionTitulo {
  font-size: 48px;
  color: #111111;
  font-weight: bold;
}
.contenedorCombinacion p {
  font-size: 20px;
  margin: 5px 20px;
}
.contenedorHabilidades {
  margin: 50px 0 90px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 32px;
}
.habilidadSubtitulo {
  margin: 16px 0 8px 0;
  font-size: 24px;
  color: #324871;
  font-weight: bold;
}
.habilidadUno p,
.habilidadDos p,
.habilidadTres p {
  font-size: 16px;
  font-family: "Gilroy", sans-serif;
}
.contenedorTipoCuentas {
  background-color: black;
  padding-top: 30px;
  padding-bottom: 90px;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 85%);
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 85%);
}
.tipoCuentas {
  width: 70%;
  margin: 10px auto;
  display: grid;
  grid-template-columns: 1fr 2fr;
}
.cuentasBotonTitulo {
  color: white;
  font-weight: bold;
  font-size: 56px;
  margin-top: 20%;
  margin-right: 70px;
}
.explicacionCuentas {
  color: white;
  padding: 30px 0;
}
.explicacionCuentas div p {
  font-size: 56px;
  font-weight: bold;
  border-top: 2px solid #13ffcf;
}

.cuentaInicial {
  width: 28%;
  display: inline-block;
}
.cuentaBasic {
  margin-left: 5%;
  width: 35%;
  display: inline-block;
  margin-right: 30%;
}
.cuentaPro {
  width: 35%;
  display: inline-block;
}
.cuentaExpert {
  margin-left: 5%;
  width: 40%;
  display: inline-block;
}
.cuentaBlack {
  width: 80%;
}
.contenedorMercado {
  width: 80%;
  margin: 90px auto;
}
.mercadoTexto {
  margin-right: 30%;
  margin-left: 5%;
}
.dudasTexto {
  width: 70%;
  margin-left: 30%;
}
.mercadoTextoTitulo,
.dudasTextoTitulo {
  font-size: 40px;
  font-weight: bold;
  line-height: 1.2;
}
.dudasTextoTitulo {
  margin-top: 20%;
}
.mercadoTexto p,
.dudasTexto p {
  font-size: 20px;
}
.dudasTexto p {
  margin-right: 30px;
}
.mercado {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.mercadoImagen {
  text-align: right;
}
.mercadoImagen img {
  width: 60%;
}
.dudas {
  margin-top: 100px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.dudasImagen {
  text-align: center;
}
.dudasImagen img {
  width: 55%;
  margin-top: 10%;
}
.botonMercado {
  background-color: black;
  color: #13ffcf;
}
.botonDudas {
  background-color: white;
  border: 1px solid #197870;
  border-radius: 5px;
}
@media screen and (min-width: 1900px) {
  .invierte {
    width: 80%;
  }
  .liderMundial {
    margin-left: 20px;
    margin-top: 40px;
  }
}
@media screen and (max-width: 1850px) {
  .liderMundialTitulo {
    font-size: 48px;
  }
  .tipoCuentas {
    width: 90%;
  }
  .explicacionCuentas div p {
    font-size: 55px;
    text-align: left;
  }
  .dudasTexto {
    width: 80%;
    margin-left: 20%;
  }
  .mercadoImagen img {
    width: 80%;
  }
}

@media screen and (max-width: 1300px) {
  .contenedorCombinacion {
    margin: 50px auto 0 auto;
  }
  .explicacionCuentas div p {
    font-size: 50px;
  }
  .contenedorMercado {
    width: 90%;
  }
  .mercadoImagen img {
    width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .cuentaInicial {
    width: 35%;
  }
  .cuentaBasic {
    margin-left: 5%;
    width: 40%;
    margin-right: 20%;
  }
  .cuentaPro {
    width: 40%;
  }
  .cuentaExpert {
    margin-left: 5%;
    width: 50%;
  }
  .cuentaBlack {
    width: 95%;
  }
}
@media screen and (max-width: 992px) {
  .contenedorHabilidades {
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
  .invierte {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: "imagenCompu" "liderMundial";
    width: 90%;
  }
  .imagenCompu {
    grid-area: imagenCompu;
    max-height: 150px;
  }
  .liderMundial {
    grid-area: liderMundial;
    text-align: center;
  }

  .liderMundial {
    padding-bottom: 50px;
  }

  .contenedorCombinacion {
    width: 80%;
  }
  .tipoCuentas {
    grid-template-columns: 1fr;
  }
  .cuentasBoton {
    text-align: center;
  }
  .cuentasBotonTitulo {
    margin: 20px auto;
  }
  .cuentaBlack {
    width: 100%;
  }
  .cuentaPro {
    width: 45%;
  }
  .cuentaExpert {
    margin-left: 5%;
    width: 50%;
  }
  .cuentaInicial {
    margin-left: 10%;
  }
  .cuentaBasic {
    margin-right: 10%;
  }
  .explicacionCuentas div p {
    font-size: 40px;
  }
  .mercado {
    grid-template-columns: 1fr;
  }
  .contenedorMercado {
    width: 90%;
  }
  .mercadoImagen,
  .mercadoTexto {
    text-align: center;
    margin-bottom: 30px;
  }
  .mercadoTexto {
    margin-left: auto;
    margin-right: auto;
  }
  .mercadoImagen img {
    width: 70%;
  }
  .botonMercado,
  .botonDudas {
    width: 50%;
    margin: 20px auto;
  }
  .dudas {
    margin-top: 40px;
    grid-template-columns: 1fr;
    grid-template-areas: "dudasImagen" "dudasTexto";
  }
  .dudasTexto {
    grid-area: dudasTexto;
    margin: 20px auto;
    text-align: center;
  }
  .dudasTextoTitulo {
    margin-top: 30px;
  }
  .dudasImagen {
    grid-area: dudasImagen;
  }
  .botonDudas span {
    margin: 15px 0;
  }
}
.iconoContactanos {
  color: var(--colorPrincipal);
  margin-right: 10px;
  padding-top: 2px;
}

.cuentaBlack div,
.cuentaPro div,
.cuentaExpert div,
.cuentaInicial div,
.cuentaBasic div {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 500;
}

@media screen and (max-width: 576px) {
  .cuentaBlack,
  .cuentaPro,
  .cuentaExpert,
  .cuentaInicial,
  .cuentaBasic {
    display: block;
    width: 90%;
    margin: 10px auto;
  }

  .liderMundialTitulo {
    padding-top: 20px;
  }

  .contenedorCombinacionTitulo {
    font-size: 40px;
  }
  .botonContacto {
    font-size: 15px;
    padding: 10px 30px;
  }
  .explicacionCuentas div p {
    font-size: 30px;
  }
  .botonMedida {
    width: 80%;
  }
  .botonMedida span {
    font-size: 20px;
  }
  .explicacionCuentas div p {
    font-size: 30px;
  }
  .cuentasBotonTitulo {
    font-size: 48px;
  }
  .mercadoTextoTitulo {
    font-size: 30px;
  }
  .botonMercado span,
  .botonDudas span {
    font-size: 20px;
  }
  .botonMercado,
  .botonDudas {
    width: 80%;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.primeraSeccion {
  background-color: white;
  width: 100%;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    ),
    url(/static/media/fondoHeader.def3de75.png);

  background-size: 100%;
  background-repeat: no-repeat;
  grid-area: primeraSeccion;
  background-position: center;
  height: 630px;
  display: grid;
  grid-template-areas: "contenedorListaImagen";
}

.contenedorListaImagen {
  padding-top: 120px;
  width: 70%;
  margin: 20px auto;
  display: grid;
  grid-template-areas:
    "titulo imagen"
    "lista imagen";
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr 3fr;
}

.titulo {
  grid-area: titulo;
  font-family: "Titillium Web", sans-serif;
  font-weight: 300;
  padding: 20px;
}

.tituloInicial {
  font-size: 35px !important;
}

.lista {
  grid-area: lista;
  position: relative;
}

.lista ul {
  list-style: none;
  position: absolute;
  top: -30px;
}

.lista ul li {
  text-transform: uppercase;
  display: block;
  background-color: #eaf8f6;
  margin: 15px auto;
  font-family: "Titillium Web", sans-serif;
  font-weight: bolder;
  position: relative;
  font-size: 15px;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px 35px 5px 10px;
}

.lista ul li:hover,
.activePoliticas {
  background-color: var(--colorPrincipal) !important;
  color: black;
}

.lista ul li span {
  position: absolute;
  right: 10px;
  padding-left: 30px;
}

.imagen {
  grid-area: imagen;
  text-align: center;
  margin-top: 100px;
}

.imagen img {
  width: 550px;
}

/* Auxiliar */
.rotar {
  transition: all 0.15s linear;
}

.rotar.down {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.segundaSeccion {
  width: 70%;
  margin: 60px auto;
}

.segundaSeccion article {
  white-space: pre-line;
  font-family: "Lato", sans-serif;
}

.segundaSeccion article p {
  font-size: 12px;
}

.articuloUno,
.articuloDos,
.articuloTres,
.articuloCuatro,
.articuloCinco,
.articuloSeis,
.articuloSiete,
.articuloOcho,
.articuloNueve
.articuloDiez {
  display: none;
  margin-top: 10px;
}

.tituloArticulos {
  font-size: 56px;
  font-weight: bold;
}
@media (min-width: 1700px) {
  .primeraSeccion {
    height: 800px;
  }

  .contenedorListaImagen {
    grid-template-columns: 35% 65%;
  }

  .lista ul li {
    margin: 10px auto;
    padding: 10px 35px 15px 50px;
    font-size: 15px;
  }

  .tituloInicial {
    font-size: 50px;
  }
  .imagen img {
    width: 400px;
  }
}

@media (max-width: 1480px) {
.segundaSeccion {
  width: 70%;
  margin: 160px auto;
}
}

@media (max-width: 1300px) {
  .lista ul li {
    margin: 5px auto;
    padding: 5px 35px 5px 8px;
    font-size: 13px;
  }
.segundaSeccion {
  width: 70%;
  margin: 100px auto;
}
  .tituloInicial {
    font-size: 35px;
  }
}

@media (max-width: 930px) {
  .contenedorListaImagen {
    width: 90%;
  }

  .lista ul li {
    padding: 5px 25px 5px 8px;
    font-size: 12px;
  }

  .tituloInicial {
    font-size: 27px;
  }

  .imagen {
    margin-top: 30px;
  }
}

@media (max-width: 768px) {
  .contenedorListaImagen {
    display: grid;
    grid-template-areas:
      "imagen"
      "titulo"
      "lista";
    grid-template-columns: 1fr;
    grid-template-rows: 10% 35% 90%;
  }

  .tituloInicial {
    text-align: center;
  }

  .imagen img {
    width: 100px;
    display: none;
  }

  .imagen {
    margin-top: 0;
  }

  .lista ul {
    list-style: none;
    position: relative;
    margin: 10 auto 0 auto;
  }

  .segundaSeccion {
    margin: 50px auto 0;
    width: 90%;
  }
}

@media (max-width: 400px) {
  .contenedorListaImagen {
    padding-top: 20px;
    display: grid;
    grid-template-areas:
      "imagen"
      "titulo"
      "lista";
    grid-template-columns: 1fr;
    grid-template-rows: 5% 15% 60%;
  }

  .imagen img {
    width: 150px;
    display: none;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

