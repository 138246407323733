.modal-body form {
  display: flex;
  flex-direction: column;
  padding: 1rem !important;
  flex-wrap: wrap;
}

.modal-body form p {
  margin: 10px 0px 20px 0px !important;
}
.modal-body form input[type="text"],
.modal-body form input[type="password"] {
  border: 1px solid grey;
  border-radius: 3px;
  height: 35px;
  padding-left: 10px;
}

.modal form label {
  margin-top: 15px;
}
.modal-content .boton {
  width: 100%;
  text-transform: uppercase;
}

.modal-body form #btnFilled {
  width: 100%;
  text-transform: uppercase;
  margin-top: 20px;
}

.modal-body {
  padding: 0rem 1rem 1rem 1rem !important;
}

.modal-header {
  border: none !important;
  padding: 1rem 1rem 0rem 1rem !important;
  display: flex;
  align-items: center !important;
}

.modal-header .close {
  margin: 0px 20px 0px 0px !important;
  padding: 0px !important;
  font-size: 2rem;
}

#Cargando {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.63);
  position: absolute;
  margin: -16px;
  z-index: 1;
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  margin-top: -140px;
}

#Cargando2 {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.63);
  position: absolute;
  z-index: 1;
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
}

#mensajeError,
#mensajeError2 {
  text-align: left;
  margin-top: 30px;
  text-transform: uppercase;
  color: rgb(249, 35, 35);
  font-size: 0.8em;
}

.innerModalBody {
  padding: 0rem 1rem 1rem 1rem !important;
}

.innerModalBody p {
  color: black !important;
  font-weight: 800 !important;
}

.innerModalBody #btnVoid {
  width: 100%;
  height: 45px;
  text-transform: uppercase;
  text-decoration: none;
  margin-top: 10px;
}

.modal-title p {
  padding: 1rem 1rem 0rem 1rem !important;
  font-weight: bold;
  font-size: 40px;
}

#formularioSignIn {
  display: flex;
  flex-direction: column;
  padding: 0 40px 40px 40px;
  padding-bottom: 10px;
}

#formularioSignIn #btnFilled {
  margin: 20px 0px;
  text-transform: uppercase !important;
}

.formularioSignInSub {
  font-size: 2.5rem;
  margin-top: -30px;
  text-align: left;
  font-weight: bold;
}

.innerModalBody p {
  display: flex;
}

.innerModalBody button:hover,
.innerModalBody button:focus,
.innerModalBody button:active {
  background-color: var(--colorPrincipal);
  color: black;
  text-decoration: underline;
  position: relative;
}

#formularioSignIn input[type="text"],
#formularioSignIn input[type="password"],
#formularioSignIn input[type="email"] {
  border: 1px solid grey !important;
  border-radius: 3px !important;
  height: 35px !important;
  padding-left: 10px !important;
}
